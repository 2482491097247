$spaces: 0, 4, 6, 8, 10, 12, 16, 20, 24;

$positions: (
  t: 'top',
  r: 'right',
  b: 'bottom',
  l: 'left',
);

@each $space in $spaces {
  // paddings
  .p-#{$space} {
    padding: #{$space}px !important;
  }
  @each $prefix, $position in $positions {
    .p#{$prefix}-#{$space} {
      padding-#{$position}: #{$space}px !important;
    }
  }

  // margins
  .m-#{$space} {
    margin: #{$space}px !important;
  }
  @each $prefix, $position in $positions {
    .m#{$prefix}-#{$space} {
      margin-#{$position}: #{$space}px !important;
    }
  }
}
