// Primary Interface Colors
$primaryBlue: #033a6d; // Dark blue for primary branding
$secondaryBlue: #2370a3; // Secondary branding blue
$accentOrange: #f9a825; // Bright orange for CTA buttons or highlights
$grayLight: #e6e9f0; // General light gray used for card borders, lines, and disabled labels

// Background Colors
$backgroundLight: #f5f6f8; // Lightest blue for backgrounds
$backgroundDisabled: #f0f2f5; // Very light gray for disabled input fields
$backgroundCard: #e7f8fd; // Light blue for card headers
$darkBlueBackground: #052144; // Very dark blue, used for subscribe banners and impactful backgrounds
$lightGreyBackground: #faf9f8;

// Functional Colors
$error: #ff3b3b; // Bright red for errors
$errorDark: #f44336; // Dark red used for error texts
$errorLight: #fbf3f2; // Very light pink for error backgrounds
$warning: #bf2c2c; // Dark red for warnings
$warningLight: #ff9f2a; // Bright orange for less severe warnings
$warningBackground: #fff8e3; // Light yellow for warning backgrounds
$success: #43a047; // Green for success messages
$successLight: #e6f9f4; // Very light green for success backgrounds
$orangeLight: #ff9250; // Bright orange, used for less critical alerts and interactive elements
$warningYellow: #aa6000; // Dark yellow, used for warning messages and caution indicators
$grayExtraLight: #f0f5f8; // Very light gray, used for subtle backgrounds, such as under information blocks or secondary interface elements

// Text Colors
$info: #00c8ff; // Bright cyan for informational messages
$textPrimary: #042238; // Darkest blue for primary text
$textSecondary: #333f4d; // Dark blue for secondary text
$textTertiary: #445163; // Gray blue for tertiary text
$textLight: #516a80; // Light blue for less prominent text
$textPale: #6d757f; // Light gray for less important text
$textGrayLight: #acb9c8;

// Special Use Colors
$profileBlue: #5da7bc; // Soft blue for profile-related UI elements
$listBackground: #627186; // Gray blue for list backgrounds or count badges
$labelMarketingYellow: #f0dfb1; // Pale yellow for marketing labels
$progressGreen: #009e74; // Green for progress bars

// Alert Colors
$alertHigh: #ea4d45; // Red for high risk alerts
$alertMedium: #f8cd46; // Yellow for medium risk alerts
$alertLow: #5abc91; // Green for low risk alerts
$successGreen: #00805f; // Dark green, used for success states and positive indicators

// Brands and Social Media Colors
$facebookBlue: #1877f2; // Bright blue for Facebook-related buttons
$googleBlue: #4285f4; // Slightly lighter blue for Google-related buttons
$redfinRed: #8d2823; // Redfin red for branding
$reiHubPurple: #431c5d; // Dark purple for REI Hub branding

// Miscellaneous
$highlightYellow: #ffcd00; // Bright yellow used for highlighting important elements
$lightGreenBright: #04bf8d; // Bright green for alternative interactive elements
$iceBlue: #c9edff; // Light blue for cold or less emphatic elements
$iceBlueAlt: #dbe7ff; // Very light blue for alternative cold elements
$grayScale: #d8d8d8; // Light gray for borders or subtle backgrounds
$pink: #e91e63; // Bright pink, used for attention-grabbing elements or promotional highlights
$lightCardHeaderBackground: #f5f8fe; // Very light blue, used for card headers and sections
$modalLightBlueBackground: #f5f9ff; // Lightest blue, used for modal backgrounds to promote focus
$helpCardBackground: #a9f3df; // Very light mint green, used for refreshing or calm elements
$sureBlue: #022c52; // Slightly lighter than $darkBlueBackground, used for formal or serious contexts
$lightRedBackground: #feefef; // Very light red, used for background highlighting in error states
$darkGray: #687a88; // Dark gray, used for less emphasized text or subdued UI elements
$blueTag: #7fe3ff; // Light blue, used for tags and markers
$hoverGray: #d9e1e9; // Light gray, used for hover states or subtle backgrounds
$steelBlue: #4f7599; // Steel Blue, used for hover state for tabs on Premium Modal
$errorGray: #9ba7af; // Light Gray, used for error filled icon in ComparisonChart
$prominentOrangeGhost: #c85028;

// Standard Colors
$white: #ffffff; // White used for pure background
$black: #21282a; // Black used for text or deep shadows

$title-font: Poppins, sans-serif;

@mixin title-extra-bold {
  font-weight: 800;
  font-family: $title-font;
}

@mixin title-bold {
  font-weight: bold;
  font-family: $title-font;
}

@mixin title-semi-bold {
  font-weight: 600;
  font-family: $title-font;
}

@mixin title-medium {
  font-weight: 500;
  font-family: $title-font;
}

@mixin title-regular {
  font-weight: normal;
  font-family: $title-font;
}

$smallMobileBreakpoint: 320px;
$mobileBreakpoint: 479px;
$smallScreenWidth: 768px;
$largeScreenBreakpoint: 969px;
$tabsLayoutBreakpoint: 1200px;
$tabletBreakpoint: 1024px;
$bigScreenBreakpoint: 1400px;

$breakpoints: (
  smallMobileBreakpoint: $smallMobileBreakpoint,
  mobileBreakpoint: $mobileBreakpoint,
  largeScreenBreakpoint: $largeScreenBreakpoint,
  smallScreenWidth: $smallScreenWidth,
  tabsLayoutBreakpoint: $tabsLayoutBreakpoint,
  tabletBreakpoint: $tabletBreakpoint,
  bigScreenBreakpoint: $bigScreenBreakpoint,
);

// This mixin will handle larger than breakpoints
// @include media-breakpoint-up(smallMobileBreakpoint) {}
// mq-up > media breakpoint up
@mixin mq-up($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    // Use custom value passed
    @media (min-width: $breakpoint) {
      @content;
    }
  }
}

// This mixin will handle smaller than breakpoints
// @include media-breakpoint-down(bigScreenBreakpoint) {}
// mq-down > media breakpoint down
@mixin mq-down($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (max-width: $breakpoint-value) {
      @content;
    }
  } @else {
    // Use custom value passed
    @media (max-width: $breakpoint) {
      @content;
    }
  }
}

// This mixin will handle sizez between breakpoints you choose
// @include media-breakpoint-between(mobileBreakpoint, smallScreenWidth) {}
// mq-between > media breakpoint between
@mixin mq-between($lower, $upper) {
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    @if ($lower-breakpoint > $upper-breakpoint) {
      // Send error if first parameter size is bigger then the second
      @error "First parameter should represent lower value and it's size should be smaller. In your case #{$lower} > #{$upper}!";
    }

    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint)) {
      @content;
    }
  } @else {
    // If custom values
    @if ($lower > $upper) {
      // Send error if first parameter size is bigger then the second
      @error "First parameter should represent lower value and it's size should be smaller. In your case #{$lower}px > #{$upper}px!";
    }

    @media (min-width: $lower) and (max-width: ($upper)) {
      @content;
    }
  }
}

%skeleton-animation {
  background: linear-gradient(-90deg, #efefef 0%, #e6e9f0 50%, #efefef 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }

  &-dark {
    background: linear-gradient(-90deg, #042e53 0%, #033a6d 50%, #042e53 100%);
  }
}

.tt-icon-mc-apartment .path1:before {
  content: '\e900';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-apartment .path2:before {
  content: '\e901';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-application .path1:before {
  content: '\e902';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-application .path2:before {
  content: '\e903';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-archive-box .path1:before {
  content: '\e904';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-archive-box .path2:before {
  content: '\e905';
  margin-left: -1em;
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-archive-box .path3:before {
  content: '\e906';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-archive-box .path4:before {
  content: '\e907';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-archive-box .path5:before {
  content: '\e908';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-archive .path1:before {
  content: '\e909';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-archive .path2:before {
  content: '\e90a';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-bank-account .path1:before {
  content: '\e90b';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-bank-account .path2:before {
  content: '\e90c';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-bath .path1:before {
  content: '\e90d';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-bath .path2:before {
  content: '\e90e';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-bed .path1:before {
  content: '\e90f';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-bed .path2:before {
  content: '\e910';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-binoculars .path1:before {
  content: '\e911';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-binoculars .path2:before {
  content: '\e912';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-birthday .path1:before {
  content: '\e913';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-birthday .path2:before {
  content: '\e914';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-built .path1:before {
  content: '\e915';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-built .path2:before {
  content: '\e916';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-calculator .path1:before {
  content: '\e917';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-calculator .path2:before {
  content: '\e918';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-calendar .path1:before {
  content: '\e919';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-calendar .path2:before {
  content: '\e91a';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-cancel-showing .path1:before {
  content: '\e91b';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-cancel-showing .path2:before {
  content: '\e91c';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-collections .path1:before {
  content: '\e91d';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-collections .path2:before {
  content: '\e91e';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-commercial .path1:before {
  content: '\e91f';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-commercial .path2:before {
  content: '\e920';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-computer .path1:before {
  content: '\e921';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-computer .path2:before {
  content: '\e922';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-condo .path1:before {
  content: '\e923';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-condo .path2:before {
  content: '\e924';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-credit-card .path1:before {
  content: '\e925';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-credit-card .path2:before {
  content: '\e926';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-delete .path1:before {
  content: '\e927';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-delete .path2:before {
  content: '\e928';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-delete .path3:before {
  content: '\e929';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-delete .path4:before {
  content: '\e92a';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-delete .path5:before {
  content: '\e92b';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-delete .path6:before {
  content: '\e92c';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-delete .path7:before {
  content: '\e92d';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-description .path1:before {
  content: '\e92e';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-description .path2:before {
  content: '\e92f';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-document .path1:before {
  content: '\e930';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-document .path2:before {
  content: '\e931';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-duplex .path1:before {
  content: '\e932';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-duplex .path2:before {
  content: '\e933';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-e-sign .path1:before {
  content: '\e934';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-e-sign .path2:before {
  content: '\e935';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-education .path1:before {
  content: '\e936';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-education .path2:before {
  content: '\e937';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-error .path1:before {
  content: '\e938';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-error .path2:before {
  content: '\e939';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-error .path3:before {
  content: '\e93a';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-error .path4:before {
  content: '\e93b';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-evictions .path1:before {
  content: '\e93c';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-evictions .path2:before {
  content: '\e93d';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-expenses .path1:before {
  content: '\e93e';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-expenses .path2:before {
  content: '\e93f';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-feedback .path1:before {
  content: '\e940';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-feedback .path2:before {
  content: '\e941';
  margin-left: -1em;
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-feedback .path3:before {
  content: '\e942';
  margin-left: -1em;
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-feedback .path4:before {
  content: '\e943';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-feedback .path5:before {
  content: '\e944';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-feedback .path6:before {
  content: '\e945';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-feedback .path7:before {
  content: '\e946';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-feedback .path8:before {
  content: '\e947';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-flyer .path1:before {
  content: '\e948';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-flyer .path2:before {
  content: '\e949';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-flyer .path3:before {
  content: '\e94a';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-for-rent .path1:before {
  content: '\e94b';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-for-rent .path2:before {
  content: '\e94c';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-for-sale .path1:before {
  content: '\e94d';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-for-sale .path2:before {
  content: '\e94e';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-forms .path1:before {
  content: '\e94f';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-forms .path2:before {
  content: '\e950';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-graph .path1:before {
  content: '\e951';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-graph .path2:before {
  content: '\e952';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-help .path1:before {
  content: '\e953';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-help .path2:before {
  content: '\e954';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-hour-glass .path1:before {
  content: '\e955';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-hour-glass .path2:before {
  content: '\e956';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-inspect .path1:before {
  content: '\e957';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-inspect .path2:before {
  content: '\e958';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-inspect .path3:before {
  content: '\e959';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-inspect .path4:before {
  content: '\e95a';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-inspect .path5:before {
  content: '\e95b';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-inspect .path6:before {
  content: '\e95c';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-insurance .path1:before {
  content: '\e95d';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-insurance .path2:before {
  content: '\e95e';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-internet .path1:before {
  content: '\e95f';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-internet .path2:before {
  content: '\e960';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-key .path1:before {
  content: '\e961';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-key .path2:before {
  content: '\e962';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-keyboard .path1:before {
  content: '\e963';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-keyboard .path2:before {
  content: '\e964';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-land .path1:before {
  content: '\e965';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-land .path2:before {
  content: '\e966';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-leads .path1:before {
  content: '\e967';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-leads .path2:before {
  content: '\e968';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-lease-addendum .path1:before {
  content: '\e969';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-lease-addendum .path2:before {
  content: '\e96a';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-lease-ai .path1:before {
  content: '\e96b';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-lease-ai .path2:before {
  content: '\e96c';
  margin-left: -1em;
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-lease-ai .path3:before {
  content: '\e96d';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-lease-ai .path4:before {
  content: '\e96e';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-lease-ai .path5:before {
  content: '\e96f';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-lease .path1:before {
  content: '\e970';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-lease .path2:before {
  content: '\e971';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-leave-lease .path1:before {
  content: '\e972';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-leave-lease .path2:before {
  content: '\e973';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-legal .path1:before {
  content: '\e974';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-legal .path2:before {
  content: '\e975';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-lock .path1:before {
  content: '\e976';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-lock .path2:before {
  content: '\e977';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-logout .path1:before {
  content: '\e978';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-logout .path2:before {
  content: '\e979';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-mail-forwarding .path1:before {
  content: '\e97a';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-mail-forwarding .path2:before {
  content: '\e97b';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-maintenance .path1:before {
  content: '\e97c';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-maintenance .path2:before {
  content: '\e97d';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-marketing .path1:before {
  content: '\e97e';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-marketing .path2:before {
  content: '\e97f';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-messages .path1:before {
  content: '\e980';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-messages .path2:before {
  content: '\e981';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-mobile-home .path1:before {
  content: '\e982';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-mobile-home .path2:before {
  content: '\e983';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-mobile-simple .path1:before {
  content: '\e984';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-mobile-simple .path2:before {
  content: '\e985';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-mobile-simple .path3:before {
  content: '\e986';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-mobile-simple .path4:before {
  content: '\e987';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-mobile .path1:before {
  content: '\e988';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-mobile .path2:before {
  content: '\e989';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-mobile .path3:before {
  content: '\e98a';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-mobile .path4:before {
  content: '\e98b';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-mobile .path5:before {
  content: '\e98c';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-mobile .path6:before {
  content: '\e98d';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-mobile .path7:before {
  content: '\e98e';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-mobile .path8:before {
  content: '\e98f';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-mobile .path9:before {
  content: '\e990';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-mobile .path10:before {
  content: '\e991';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-mobile .path11:before {
  content: '\e992';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-move-in .path1:before {
  content: '\e993';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-move-in .path2:before {
  content: '\e994';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-move-out .path1:before {
  content: '\e995';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-move-out .path2:before {
  content: '\e996';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-moving-box .path1:before {
  content: '\e997';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-moving-box .path2:before {
  content: '\e998';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-moving .path1:before {
  content: '\e999';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-moving .path2:before {
  content: '\e99a';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-multi-family .path1:before {
  content: '\e99b';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-multi-family .path2:before {
  content: '\e99c';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-not-found .path1:before {
  content: '\e99d';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-not-found .path2:before {
  content: '\e99e';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-notification .path1:before {
  content: '\e99f';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-notification .path2:before {
  content: '\e9a0';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-payments .path1:before {
  content: '\e9a1';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-payments .path2:before {
  content: '\e9a2';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-pets .path1:before {
  content: '\e9a3';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-pets .path2:before {
  content: '\e9a4';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-phone .path1:before {
  content: '\e9a5';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-phone .path2:before {
  content: '\e9a6';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-photos .path1:before {
  content: '\e9a7';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-photos .path2:before {
  content: '\e9a8';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-picture .path1:before {
  content: '\e9a9';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-picture .path2:before {
  content: '\e9aa';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-pie-chart .path1:before {
  content: '\e9ab';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-pie-chart .path2:before {
  content: '\e9ac';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-podcast .path1:before {
  content: '\e9ad';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-podcast .path2:before {
  content: '\e9ae';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-podcast .path3:before {
  content: '\e9af';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-podcast .path4:before {
  content: '\e9b0';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-podcast .path5:before {
  content: '\e9b1';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-podcast .path6:before {
  content: '\e9b2';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-podcast .path7:before {
  content: '\e9b3';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-podcast .path8:before {
  content: '\e9b4';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-podcast .path9:before {
  content: '\e9b5';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-podcast .path10:before {
  content: '\e9b6';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-podcast .path11:before {
  content: '\e9b7';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-podcast .path12:before {
  content: '\e9b8';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-podcast .path13:before {
  content: '\e9b9';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-podcast .path14:before {
  content: '\e9ba';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-pre-screener .path1:before {
  content: '\e9bb';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-pre-screener .path2:before {
  content: '\e9bc';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-profile .path1:before {
  content: '\e9bd';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-profile .path2:before {
  content: '\e9be';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-property-management .path1:before {
  content: '\e9bf';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-property-management .path2:before {
  content: '\e9c0';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-property .path1:before {
  content: '\e9c1';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-property .path2:before {
  content: '\e9c2';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-purchase-house .path1:before {
  content: '\e9c3';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-purchase-house .path2:before {
  content: '\e9c4';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-receiving-money .path1:before {
  content: '\e9c5';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-receiving-money .path2:before {
  content: '\e9c6';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-refer .path1:before {
  content: '\e9c7';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-refer .path2:before {
  content: '\e9c8';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-renew .path1:before {
  content: '\e9c9';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-renew .path2:before {
  content: '\e9ca';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-rent-estimate .path1:before {
  content: '\e9cb';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-rent-estimate .path2:before {
  content: '\e9cc';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-rent-reporting .path1:before {
  content: '\e9cd';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-rent-reporting .path2:before {
  content: '\e9ce';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-rent-reporting .path3:before {
  content: '\e9cf';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-rent-reporting .path4:before {
  content: '\e9d0';
  margin-left: -1em;
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-rent-reporting .path5:before {
  content: '\e9d1';
  margin-left: -1em;
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-rent-reporting .path6:before {
  content: '\e9d2';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-reputation .path1:before {
  content: '\e9d3';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-reputation .path2:before {
  content: '\e9d4';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-resources .path1:before {
  content: '\e9d5';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-resources .path2:before {
  content: '\e9d6';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-revert .path1:before {
  content: '\e9d7';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-revert .path2:before {
  content: '\e9d8';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-roommates .path1:before {
  content: '\e9d9';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-roommates .path2:before {
  content: '\e9da';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-screening .path1:before {
  content: '\e9db';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-screening .path2:before {
  content: '\e9dc';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-security .path1:before {
  content: '\e9dd';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-security .path2:before {
  content: '\e9de';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-sent .path1:before {
  content: '\e9df';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-sent .path2:before {
  content: '\e9e0';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-settings .path1:before {
  content: '\e9e1';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-settings .path2:before {
  content: '\e9e2';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-settings .path3:before {
  content: '\e9e3';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-sheet .path1:before {
  content: '\e9e4';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-sheet .path2:before {
  content: '\e9e5';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-showings .path1:before {
  content: '\e9e6';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-showings .path2:before {
  content: '\e9e7';
  margin-left: -1em;
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-showings .path3:before {
  content: '\e9e8';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-showings .path4:before {
  content: '\e9e9';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-showings .path5:before {
  content: '\e9ea';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-showings .path6:before {
  content: '\e9eb';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-showings .path7:before {
  content: '\e9ec';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-showings .path8:before {
  content: '\e9ed';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-showings .path9:before {
  content: '\e9ee';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-showings .path10:before {
  content: '\e9ef';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-smoking .path1:before {
  content: '\e9f0';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-smoking .path2:before {
  content: '\e9f1';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-square-feet .path1:before {
  content: '\e9f2';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-square-feet .path2:before {
  content: '\e9f3';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-star .path1:before {
  content: '\e9f4';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-star .path2:before {
  content: '\e9f5';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-subscription .path1:before {
  content: '\e9f6';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-subscription .path2:before {
  content: '\e9f7';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-success .path1:before {
  content: '\e9f8';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-success .path2:before {
  content: '\e9f9';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-success .path3:before {
  content: '\e9fa';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-success .path4:before {
  content: '\e9fb';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-success .path5:before {
  content: '\e9fc';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-success .path6:before {
  content: '\e9fd';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-success .path7:before {
  content: '\e9fe';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-success .path8:before {
  content: '\e9ff';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-success .path9:before {
  content: '\ea00';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-success .path10:before {
  content: '\ea01';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-success .path11:before {
  content: '\ea02';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-support .path1:before {
  content: '\ea03';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-support .path2:before {
  content: '\ea04';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-tenants .path1:before {
  content: '\ea05';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-tenants .path2:before {
  content: '\ea06';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-thumbs-up .path1:before {
  content: '\ea07';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-thumbs-up .path2:before {
  content: '\ea08';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-update .path1:before {
  content: '\ea09';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-update .path2:before {
  content: '\ea0a';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-upload .path1:before {
  content: '\ea0b';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-upload .path2:before {
  content: '\ea0c';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-utilities .path1:before {
  content: '\ea0d';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-utilities .path2:before {
  content: '\ea0e';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-vehicle .path1:before {
  content: '\ea0f';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-vehicle .path2:before {
  content: '\ea10';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-verify .path1:before {
  content: '\ea11';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-verify .path2:before {
  content: '\ea12';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-virtual-showing .path1:before {
  content: '\ea13';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-virtual-showing .path2:before {
  content: '\ea14';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-warning .path1:before {
  content: '\ea15';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-warning .path2:before {
  content: '\ea16';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-yard-sign .path1:before {
  content: '\ea17';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-yard-sign .path2:before {
  content: '\ea18';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-youtube .path1:before {
  content: '\ea19';
  color: var(--tt-icon-mc-fill);
}
.tt-icon-mc-youtube .path2:before {
  content: '\ea1a';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
.tt-icon-mc-youtube .path3:before {
  content: '\ea1b';
  margin-left: -1em;
  color: rgb(4, 34, 56);
}
