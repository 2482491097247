// Primary Interface Colors
$primaryBlue: #033a6d; // Dark blue for primary branding
$secondaryBlue: #2370a3; // Secondary branding blue
$accentOrange: #f9a825; // Bright orange for CTA buttons or highlights
$grayLight: #e6e9f0; // General light gray used for card borders, lines, and disabled labels

// Background Colors
$backgroundLight: #f5f6f8; // Lightest blue for backgrounds
$backgroundDisabled: #f0f2f5; // Very light gray for disabled input fields
$backgroundCard: #e7f8fd; // Light blue for card headers
$darkBlueBackground: #052144; // Very dark blue, used for subscribe banners and impactful backgrounds
$lightGreyBackground: #faf9f8;

// Functional Colors
$error: #ff3b3b; // Bright red for errors
$errorDark: #f44336; // Dark red used for error texts
$errorLight: #fbf3f2; // Very light pink for error backgrounds
$warning: #bf2c2c; // Dark red for warnings
$warningLight: #ff9f2a; // Bright orange for less severe warnings
$warningBackground: #fff8e3; // Light yellow for warning backgrounds
$success: #43a047; // Green for success messages
$successLight: #e6f9f4; // Very light green for success backgrounds
$orangeLight: #ff9250; // Bright orange, used for less critical alerts and interactive elements
$warningYellow: #aa6000; // Dark yellow, used for warning messages and caution indicators
$grayExtraLight: #f0f5f8; // Very light gray, used for subtle backgrounds, such as under information blocks or secondary interface elements

// Text Colors
$info: #00c8ff; // Bright cyan for informational messages
$textPrimary: #042238; // Darkest blue for primary text
$textSecondary: #333f4d; // Dark blue for secondary text
$textTertiary: #445163; // Gray blue for tertiary text
$textLight: #516a80; // Light blue for less prominent text
$textPale: #6d757f; // Light gray for less important text
$textGrayLight: #acb9c8;

// Special Use Colors
$profileBlue: #5da7bc; // Soft blue for profile-related UI elements
$listBackground: #627186; // Gray blue for list backgrounds or count badges
$labelMarketingYellow: #f0dfb1; // Pale yellow for marketing labels
$progressGreen: #009e74; // Green for progress bars

// Alert Colors
$alertHigh: #ea4d45; // Red for high risk alerts
$alertMedium: #f8cd46; // Yellow for medium risk alerts
$alertLow: #5abc91; // Green for low risk alerts
$successGreen: #00805f; // Dark green, used for success states and positive indicators

// Brands and Social Media Colors
$facebookBlue: #1877f2; // Bright blue for Facebook-related buttons
$googleBlue: #4285f4; // Slightly lighter blue for Google-related buttons
$redfinRed: #8d2823; // Redfin red for branding
$reiHubPurple: #431c5d; // Dark purple for REI Hub branding

// Miscellaneous
$highlightYellow: #ffcd00; // Bright yellow used for highlighting important elements
$lightGreenBright: #04bf8d; // Bright green for alternative interactive elements
$iceBlue: #c9edff; // Light blue for cold or less emphatic elements
$iceBlueAlt: #dbe7ff; // Very light blue for alternative cold elements
$grayScale: #d8d8d8; // Light gray for borders or subtle backgrounds
$pink: #e91e63; // Bright pink, used for attention-grabbing elements or promotional highlights
$lightCardHeaderBackground: #f5f8fe; // Very light blue, used for card headers and sections
$modalLightBlueBackground: #f5f9ff; // Lightest blue, used for modal backgrounds to promote focus
$helpCardBackground: #a9f3df; // Very light mint green, used for refreshing or calm elements
$sureBlue: #022c52; // Slightly lighter than $darkBlueBackground, used for formal or serious contexts
$lightRedBackground: #feefef; // Very light red, used for background highlighting in error states
$darkGray: #687a88; // Dark gray, used for less emphasized text or subdued UI elements
$blueTag: #7fe3ff; // Light blue, used for tags and markers
$hoverGray: #d9e1e9; // Light gray, used for hover states or subtle backgrounds
$steelBlue: #4f7599; // Steel Blue, used for hover state for tabs on Premium Modal
$errorGray: #9ba7af; // Light Gray, used for error filled icon in ComparisonChart
$prominentOrangeGhost: #c85028;

// Standard Colors
$white: #ffffff; // White used for pure background
$black: #21282a; // Black used for text or deep shadows

$title-font: Poppins, sans-serif;

@mixin title-extra-bold {
  font-weight: 800;
  font-family: $title-font;
}

@mixin title-bold {
  font-weight: bold;
  font-family: $title-font;
}

@mixin title-semi-bold {
  font-weight: 600;
  font-family: $title-font;
}

@mixin title-medium {
  font-weight: 500;
  font-family: $title-font;
}

@mixin title-regular {
  font-weight: normal;
  font-family: $title-font;
}

$smallMobileBreakpoint: 320px;
$mobileBreakpoint: 479px;
$smallScreenWidth: 768px;
$largeScreenBreakpoint: 969px;
$tabsLayoutBreakpoint: 1200px;
$tabletBreakpoint: 1024px;
$bigScreenBreakpoint: 1400px;

$breakpoints: (
  smallMobileBreakpoint: $smallMobileBreakpoint,
  mobileBreakpoint: $mobileBreakpoint,
  largeScreenBreakpoint: $largeScreenBreakpoint,
  smallScreenWidth: $smallScreenWidth,
  tabsLayoutBreakpoint: $tabsLayoutBreakpoint,
  tabletBreakpoint: $tabletBreakpoint,
  bigScreenBreakpoint: $bigScreenBreakpoint,
);

// This mixin will handle larger than breakpoints
// @include media-breakpoint-up(smallMobileBreakpoint) {}
// mq-up > media breakpoint up
@mixin mq-up($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    // Use custom value passed
    @media (min-width: $breakpoint) {
      @content;
    }
  }
}

// This mixin will handle smaller than breakpoints
// @include media-breakpoint-down(bigScreenBreakpoint) {}
// mq-down > media breakpoint down
@mixin mq-down($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (max-width: $breakpoint-value) {
      @content;
    }
  } @else {
    // Use custom value passed
    @media (max-width: $breakpoint) {
      @content;
    }
  }
}

// This mixin will handle sizez between breakpoints you choose
// @include media-breakpoint-between(mobileBreakpoint, smallScreenWidth) {}
// mq-between > media breakpoint between
@mixin mq-between($lower, $upper) {
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    @if ($lower-breakpoint > $upper-breakpoint) {
      // Send error if first parameter size is bigger then the second
      @error "First parameter should represent lower value and it's size should be smaller. In your case #{$lower} > #{$upper}!";
    }

    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint)) {
      @content;
    }
  } @else {
    // If custom values
    @if ($lower > $upper) {
      // Send error if first parameter size is bigger then the second
      @error "First parameter should represent lower value and it's size should be smaller. In your case #{$lower}px > #{$upper}px!";
    }

    @media (min-width: $lower) and (max-width: ($upper)) {
      @content;
    }
  }
}

%skeleton-animation {
  background: linear-gradient(-90deg, #efefef 0%, #e6e9f0 50%, #efefef 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }

  &-dark {
    background: linear-gradient(-90deg, #042e53 0%, #033a6d 50%, #042e53 100%);
  }
}

.tt-icon-percentage:before {
  content: '\e986';
}
.tt-icon-dollar:before {
  content: '\e985';
}
.tt-icon-folder-open-filled:before {
  content: '\e983';
}
.tt-icon-folder-filled:before {
  content: '\e984';
}
.tt-icon-flag-filled:before {
  content: '\e905';
}
.tt-icon-dollar-sign-filled:before {
  content: '\e900';
}
.tt-icon-error-1-filled:before {
  content: '\e901';
}
.tt-icon-circle-star-filled:before {
  content: '\e902';
}
.tt-icon-thumbs-up-filled:before {
  content: '\e903';
}
.tt-icon-question-filled:before {
  content: '\e904';
}
.tt-icon-verified-filled:before {
  content: '\e906';
}
.tt-icon-thumbs-down-filled:before {
  content: '\e907';
}
.tt-icon-play-filled:before {
  content: '\e908';
}
.tt-icon-home-filled:before {
  content: '\e909';
}
.tt-icon-star-filled:before {
  content: '\e90a';
}
.tt-icon-lock-filled:before {
  content: '\e90b';
}
.tt-icon-checkmark-filled:before {
  content: '\e90c';
}
.tt-icon-plus-filled:before {
  content: '\e90d';
}
.tt-icon-exclamation-filled:before {
  content: '\e90e';
}
.tt-icon-error-2-filled:before {
  content: '\e90f';
}
.tt-icon-info-filled:before {
  content: '\e910';
}
.tt-icon-lightning-filled:before {
  content: '\e911';
}
.tt-icon-warning-filled:before {
  content: '\e912';
}
.tt-icon-accounting:before {
  content: '\e913';
}
.tt-icon-add-thick:before {
  content: '\e914';
}
.tt-icon-add:before {
  content: '\e915';
}
.tt-icon-additional-occupants:before {
  content: '\e916';
}
.tt-icon-ai:before {
  content: '\e917';
}
.tt-icon-alarm:before {
  content: '\e918';
}
.tt-icon-apartment:before {
  content: '\e919';
}
.tt-icon-applicants:before {
  content: '\e91a';
}
.tt-icon-archive:before {
  content: '\e91b';
}
.tt-icon-arrow-backward:before {
  content: '\e91c';
}
.tt-icon-arrow-down:before {
  content: '\e91d';
}
.tt-icon-arrow-forward:before {
  content: '\e91e';
}
.tt-icon-arrow-left:before {
  content: '\e91f';
}
.tt-icon-arrow-right:before {
  content: '\e920';
}
.tt-icon-arrow-up:before {
  content: '\e921';
}
.tt-icon-attachment:before {
  content: '\e922';
}
.tt-icon-bank-account:before {
  content: '\e923';
}
.tt-icon-basketball:before {
  content: '\e924';
}
.tt-icon-baths:before {
  content: '\e925';
}
.tt-icon-beds:before {
  content: '\e926';
}
.tt-icon-bicycle:before {
  content: '\e927';
}
.tt-icon-binoculars:before {
  content: '\e928';
}
.tt-icon-block:before {
  content: '\e929';
}
.tt-icon-bug:before {
  content: '\e92a';
}
.tt-icon-built:before {
  content: '\e92b';
}
.tt-icon-calendar:before {
  content: '\e92c';
}
.tt-icon-camera:before {
  content: '\e92d';
}
.tt-icon-cancel-showing:before {
  content: '\e92e';
}
.tt-icon-cancel:before {
  content: '\e92f';
}
.tt-icon-carport:before {
  content: '\e930';
}
.tt-icon-cash:before {
  content: '\e931';
}
.tt-icon-categories:before {
  content: '\e932';
}
.tt-icon-check:before {
  content: '\e933';
}
.tt-icon-checkmark-plain:before {
  content: '\e934';
}
.tt-icon-checkmark:before {
  content: '\e935';
}
.tt-icon-cheeseburger:before {
  content: '\e936';
}
.tt-icon-chevron-arrow-down:before {
  content: '\e937';
}
.tt-icon-chevron-arrow-left:before {
  content: '\e938';
}
.tt-icon-chevron-arrow-right-circle:before {
  content: '\e939';
}
.tt-icon-chevron-arrow-right:before {
  content: '\e93a';
}
.tt-icon-chevron-arrow-up:before {
  content: '\e93b';
}
.tt-icon-close:before {
  content: '\e93c';
}
.tt-icon-code-violation:before {
  content: '\e93d';
}
.tt-icon-collapse-horizontal:before {
  content: '\e93e';
}
.tt-icon-collapse:before {
  content: '\e93f';
}
.tt-icon-collections:before {
  content: '\e940';
}
.tt-icon-columns:before {
  content: '\e941';
}
.tt-icon-company:before {
  content: '\e942';
}
.tt-icon-computer:before {
  content: '\e943';
}
.tt-icon-condo:before {
  content: '\e944';
}
.tt-icon-contact:before {
  content: '\e945';
}
.tt-icon-copy:before {
  content: '\e946';
}
.tt-icon-coverage:before {
  content: '\e947';
}
.tt-icon-credit-card:before {
  content: '\e948';
}
.tt-icon-dashboard:before {
  content: '\e949';
}
.tt-icon-death:before {
  content: '\e94a';
}
.tt-icon-debit:before {
  content: '\e94b';
}
.tt-icon-deck:before {
  content: '\e94c';
}
.tt-icon-delete:before {
  content: '\e94d';
}
.tt-icon-deny-payments:before {
  content: '\e94e';
}
.tt-icon-deposit:before {
  content: '\e94f';
}
.tt-icon-description:before {
  content: '\e950';
}
.tt-icon-details:before {
  content: '\e951';
}
.tt-icon-dishwasher:before {
  content: '\e952';
}
.tt-icon-disposal:before {
  content: '\e953';
}
.tt-icon-dock-right:before {
  content: '\e954';
}
.tt-icon-document:before {
  content: '\e955';
}
.tt-icon-download:before {
  content: '\e956';
}
.tt-icon-dryer:before {
  content: '\e957';
}
.tt-icon-e-sign:before {
  content: '\e958';
}
.tt-icon-edit:before {
  content: '\e959';
}
.tt-icon-education:before {
  content: '\e95a';
}
.tt-icon-electricity:before {
  content: '\e95b';
}
.tt-icon-email-reminder:before {
  content: '\e95c';
}
.tt-icon-email:before {
  content: '\e95d';
}
.tt-icon-emoticons:before {
  content: '\e95e';
}
.tt-icon-employers:before {
  content: '\e95f';
}
.tt-icon-energy-efficient:before {
  content: '\e960';
}
.tt-icon-environmental-liability:before {
  content: '\e961';
}
.tt-icon-error:before {
  content: '\e962';
}
.tt-icon-evictions:before {
  content: '\e963';
}
.tt-icon-expand-horizontal:before {
  content: '\e964';
}
.tt-icon-expand:before {
  content: '\e965';
}
.tt-icon-expense:before {
  content: '\e966';
}
.tt-icon-explosion:before {
  content: '\e967';
}
.tt-icon-fence:before {
  content: '\e968';
}
.tt-icon-filter:before {
  content: '\e969';
}
.tt-icon-fireplace:before {
  content: '\e96a';
}
.tt-icon-fitness:before {
  content: '\e96b';
}
.tt-icon-flag:before {
  content: '\e96c';
}
.tt-icon-flood:before {
  content: '\e96d';
}
.tt-icon-flooring:before {
  content: '\e96e';
}
.tt-icon-forms:before {
  content: '\e96f';
}
.tt-icon-freezer:before {
  content: '\e970';
}
.tt-icon-furniture:before {
  content: '\e971';
}
.tt-icon-garage:before {
  content: '\e972';
}
.tt-icon-gas:before {
  content: '\e973';
}
.tt-icon-help:before {
  content: '\e974';
}
.tt-icon-hide:before {
  content: '\e975';
}
.tt-icon-hoa:before {
  content: '\e976';
}
.tt-icon-home:before {
  content: '\e977';
}
.tt-icon-house-add:before {
  content: '\e978';
}
.tt-icon-house-checkmark:before {
  content: '\e979';
}
.tt-icon-house-inspect:before {
  content: '\e97a';
}
.tt-icon-house-money:before {
  content: '\e97b';
}
.tt-icon-house-star:before {
  content: '\e97c';
}
.tt-icon-id:before {
  content: '\e97d';
}
.tt-icon-impersonate:before {
  content: '\e97e';
}
.tt-icon-inbox:before {
  content: '\e97f';
}
.tt-icon-info:before {
  content: '\e980';
}
.tt-icon-insurance:before {
  content: '\e981';
}
.tt-icon-intercom:before {
  content: '\e982';
}
.tt-icon-internet:before {
  content: '\e98c';
}
.tt-icon-key:before {
  content: '\e98d';
}
.tt-icon-landlord-status:before {
  content: '\e98e';
}
.tt-icon-laundry:before {
  content: '\e98f';
}
.tt-icon-lawn:before {
  content: '\e990';
}
.tt-icon-leads:before {
  content: '\e991';
}
.tt-icon-lease-addendum:before {
  content: '\e992';
}
.tt-icon-leases:before {
  content: '\e993';
}
.tt-icon-leave-review:before {
  content: '\e994';
}
.tt-icon-link:before {
  content: '\e995';
}
.tt-icon-location:before {
  content: '\e996';
}
.tt-icon-lock:before {
  content: '\e997';
}
.tt-icon-logout:before {
  content: '\e998';
}
.tt-icon-magic-wand:before {
  content: '\e999';
}
.tt-icon-mailing-address:before {
  content: '\e99a';
}
.tt-icon-maintenance:before {
  content: '\e99b';
}
.tt-icon-map:before {
  content: '\e99c';
}
.tt-icon-mark-contacted:before {
  content: '\e99d';
}
.tt-icon-marketing:before {
  content: '\e99e';
}
.tt-icon-messages:before {
  content: '\e99f';
}
.tt-icon-meth:before {
  content: '\e9a0';
}
.tt-icon-microwave:before {
  content: '\e9a1';
}
.tt-icon-military-installation:before {
  content: '\e9a2';
}
.tt-icon-minimize:before {
  content: '\e9a3';
}
.tt-icon-minus:before {
  content: '\e9a4';
}
.tt-icon-mobile-home:before {
  content: '\e9a5';
}
.tt-icon-mold:before {
  content: '\e9a6';
}
.tt-icon-more-horizontal:before {
  content: '\e9a7';
}
.tt-icon-more-navigation:before {
  content: '\e9a8';
}
.tt-icon-more-vertical:before {
  content: '\e9a9';
}
.tt-icon-move-in:before {
  content: '\e9aa';
}
.tt-icon-move:before {
  content: '\e9ab';
}
.tt-icon-moving:before {
  content: '\e9ac';
}
.tt-icon-multi-family:before {
  content: '\e9ad';
}
.tt-icon-new-message:before {
  content: '\e9ae';
}
.tt-icon-new-window:before {
  content: '\e9af';
}
.tt-icon-notes:before {
  content: '\e9b0';
}
.tt-icon-notification:before {
  content: '\e9b1';
}
.tt-icon-oven:before {
  content: '\e9b2';
}
.tt-icon-paint:before {
  content: '\e9b3';
}
.tt-icon-park:before {
  content: '\e9b4';
}
.tt-icon-parking:before {
  content: '\e9b5';
}
.tt-icon-payments:before {
  content: '\e9b6';
}
.tt-icon-personalize:before {
  content: '\e9b7';
}
.tt-icon-pets:before {
  content: '\e9b8';
}
.tt-icon-phone-mobile:before {
  content: '\e9b9';
}
.tt-icon-phone:before {
  content: '\e9ba';
}
.tt-icon-photos:before {
  content: '\e9bb';
}
.tt-icon-picture:before {
  content: '\e9bc';
}
.tt-icon-plus:before {
  content: '\e9bd';
}
.tt-icon-podcast:before {
  content: '\e9be';
}
.tt-icon-pool:before {
  content: '\e9bf';
}
.tt-icon-pre-screener:before {
  content: '\e9c0';
}
.tt-icon-preview:before {
  content: '\e9c1';
}
.tt-icon-print:before {
  content: '\e9c2';
}
.tt-icon-products:before {
  content: '\e9c3';
}
.tt-icon-profile:before {
  content: '\e9c4';
}
.tt-icon-properties:before {
  content: '\e9c5';
}
.tt-icon-quick-message:before {
  content: '\e9c6';
}
.tt-icon-radation:before {
  content: '\e9c7';
}
.tt-icon-recurring:before {
  content: '\e9c8';
}
.tt-icon-refer:before {
  content: '\e9c9';
}
.tt-icon-refridgerator:before {
  content: '\e9ca';
}
.tt-icon-rent-estimate:before {
  content: '\e9cb';
}
.tt-icon-rent-reporting:before {
  content: '\e9cc';
}
.tt-icon-renters:before {
  content: '\e9cd';
}
.tt-icon-reporting:before {
  content: '\e9ce';
}
.tt-icon-request-info:before {
  content: '\e9cf';
}
.tt-icon-resources:before {
  content: '\e9d0';
}
.tt-icon-revert:before {
  content: '\e9d1';
}
.tt-icon-rotate:before {
  content: '\e9d2';
}
.tt-icon-satellite-tv:before {
  content: '\e9d3';
}
.tt-icon-save:before {
  content: '\e9d8';
}
.tt-icon-scan:before {
  content: '\e9d9';
}
.tt-icon-screen-tenant:before {
  content: '\e9da';
}
.tt-icon-search:before {
  content: '\e9db';
}
.tt-icon-security-camera:before {
  content: '\e9dc';
}
.tt-icon-send:before {
  content: '\e9dd';
}
.tt-icon-settings:before {
  content: '\e9de';
}
.tt-icon-sewage:before {
  content: '\e9df';
}
.tt-icon-share:before {
  content: '\e9e0';
}
.tt-icon-sheet:before {
  content: '\e9e1';
}
.tt-icon-show:before {
  content: '\e9e2';
}
.tt-icon-showings:before {
  content: '\e9e3';
}
.tt-icon-smoking:before {
  content: '\e9e4';
}
.tt-icon-snow:before {
  content: '\e9e5';
}
.tt-icon-sort:before {
  content: '\e9e6';
}
.tt-icon-spa:before {
  content: '\e9e7';
}
.tt-icon-sprinkler:before {
  content: '\e9e8';
}
.tt-icon-square-feet:before {
  content: '\e9e9';
}
.tt-icon-star:before {
  content: '\e9ea';
}
.tt-icon-success:before {
  content: '\e9eb';
}
.tt-icon-syndication:before {
  content: '\e9ec';
}
.tt-icon-table:before {
  content: '\e9ed';
}
.tt-icon-tenants:before {
  content: '\e9ee';
}
.tt-icon-test-account:before {
  content: '\e9ef';
}
.tt-icon-text:before {
  content: '\e9f0';
}
.tt-icon-thumbs-down:before {
  content: '\e9f1';
}
.tt-icon-thumbs-up:before {
  content: '\e9f2';
}
.tt-icon-time:before {
  content: '\e9f3';
}
.tt-icon-timeline:before {
  content: '\e9f4';
}
.tt-icon-title:before {
  content: '\e9f5';
}
.tt-icon-toolbox:before {
  content: '\e9f6';
}
.tt-icon-townhouse:before {
  content: '\e9f7';
}
.tt-icon-tv:before {
  content: '\e9f8';
}
.tt-icon-unlock:before {
  content: '\e9f9';
}
.tt-icon-upload:before {
  content: '\e9fa';
}
.tt-icon-utilities:before {
  content: '\e9fb';
}
.tt-icon-vaulted-ceiling:before {
  content: '\e9fc';
}
.tt-icon-video:before {
  content: '\e9fd';
}
.tt-icon-wall:before {
  content: '\e9fe';
}
.tt-icon-warning:before {
  content: '\e9ff';
}
.tt-icon-water:before {
  content: '\ea00';
}
.tt-icon-wheelchair:before {
  content: '\ea01';
}
.tt-icon-yard-sign:before {
  content: '\ea02';
}
