// Primary Interface Colors
$primaryBlue: #033a6d; // Dark blue for primary branding
$secondaryBlue: #2370a3; // Secondary branding blue
$accentOrange: #f9a825; // Bright orange for CTA buttons or highlights
$grayLight: #e6e9f0; // General light gray used for card borders, lines, and disabled labels

// Background Colors
$backgroundLight: #f5f6f8; // Lightest blue for backgrounds
$backgroundDisabled: #f0f2f5; // Very light gray for disabled input fields
$backgroundCard: #e7f8fd; // Light blue for card headers
$darkBlueBackground: #052144; // Very dark blue, used for subscribe banners and impactful backgrounds
$lightGreyBackground: #faf9f8;

// Functional Colors
$error: #ff3b3b; // Bright red for errors
$errorDark: #f44336; // Dark red used for error texts
$errorLight: #fbf3f2; // Very light pink for error backgrounds
$warning: #bf2c2c; // Dark red for warnings
$warningLight: #ff9f2a; // Bright orange for less severe warnings
$warningBackground: #fff8e3; // Light yellow for warning backgrounds
$success: #43a047; // Green for success messages
$successLight: #e6f9f4; // Very light green for success backgrounds
$orangeLight: #ff9250; // Bright orange, used for less critical alerts and interactive elements
$warningYellow: #aa6000; // Dark yellow, used for warning messages and caution indicators
$grayExtraLight: #f0f5f8; // Very light gray, used for subtle backgrounds, such as under information blocks or secondary interface elements

// Text Colors
$info: #00c8ff; // Bright cyan for informational messages
$textPrimary: #042238; // Darkest blue for primary text
$textSecondary: #333f4d; // Dark blue for secondary text
$textTertiary: #445163; // Gray blue for tertiary text
$textLight: #516a80; // Light blue for less prominent text
$textPale: #6d757f; // Light gray for less important text
$textGrayLight: #acb9c8;

// Special Use Colors
$profileBlue: #5da7bc; // Soft blue for profile-related UI elements
$listBackground: #627186; // Gray blue for list backgrounds or count badges
$labelMarketingYellow: #f0dfb1; // Pale yellow for marketing labels
$progressGreen: #009e74; // Green for progress bars

// Alert Colors
$alertHigh: #ea4d45; // Red for high risk alerts
$alertMedium: #f8cd46; // Yellow for medium risk alerts
$alertLow: #5abc91; // Green for low risk alerts
$successGreen: #00805f; // Dark green, used for success states and positive indicators

// Brands and Social Media Colors
$facebookBlue: #1877f2; // Bright blue for Facebook-related buttons
$googleBlue: #4285f4; // Slightly lighter blue for Google-related buttons
$redfinRed: #8d2823; // Redfin red for branding
$reiHubPurple: #431c5d; // Dark purple for REI Hub branding

// Miscellaneous
$highlightYellow: #ffcd00; // Bright yellow used for highlighting important elements
$lightGreenBright: #04bf8d; // Bright green for alternative interactive elements
$iceBlue: #c9edff; // Light blue for cold or less emphatic elements
$iceBlueAlt: #dbe7ff; // Very light blue for alternative cold elements
$grayScale: #d8d8d8; // Light gray for borders or subtle backgrounds
$pink: #e91e63; // Bright pink, used for attention-grabbing elements or promotional highlights
$lightCardHeaderBackground: #f5f8fe; // Very light blue, used for card headers and sections
$modalLightBlueBackground: #f5f9ff; // Lightest blue, used for modal backgrounds to promote focus
$helpCardBackground: #a9f3df; // Very light mint green, used for refreshing or calm elements
$sureBlue: #022c52; // Slightly lighter than $darkBlueBackground, used for formal or serious contexts
$lightRedBackground: #feefef; // Very light red, used for background highlighting in error states
$darkGray: #687a88; // Dark gray, used for less emphasized text or subdued UI elements
$blueTag: #7fe3ff; // Light blue, used for tags and markers
$hoverGray: #d9e1e9; // Light gray, used for hover states or subtle backgrounds
$steelBlue: #4f7599; // Steel Blue, used for hover state for tabs on Premium Modal
$errorGray: #9ba7af; // Light Gray, used for error filled icon in ComparisonChart
$prominentOrangeGhost: #c85028;

// Standard Colors
$white: #ffffff; // White used for pure background
$black: #21282a; // Black used for text or deep shadows

$title-font: Poppins, sans-serif;

@mixin title-extra-bold {
  font-weight: 800;
  font-family: $title-font;
}

@mixin title-bold {
  font-weight: bold;
  font-family: $title-font;
}

@mixin title-semi-bold {
  font-weight: 600;
  font-family: $title-font;
}

@mixin title-medium {
  font-weight: 500;
  font-family: $title-font;
}

@mixin title-regular {
  font-weight: normal;
  font-family: $title-font;
}

$smallMobileBreakpoint: 320px;
$mobileBreakpoint: 479px;
$smallScreenWidth: 768px;
$largeScreenBreakpoint: 969px;
$tabsLayoutBreakpoint: 1200px;
$tabletBreakpoint: 1024px;
$bigScreenBreakpoint: 1400px;

$breakpoints: (
  smallMobileBreakpoint: $smallMobileBreakpoint,
  mobileBreakpoint: $mobileBreakpoint,
  largeScreenBreakpoint: $largeScreenBreakpoint,
  smallScreenWidth: $smallScreenWidth,
  tabsLayoutBreakpoint: $tabsLayoutBreakpoint,
  tabletBreakpoint: $tabletBreakpoint,
  bigScreenBreakpoint: $bigScreenBreakpoint,
);

// This mixin will handle larger than breakpoints
// @include media-breakpoint-up(smallMobileBreakpoint) {}
// mq-up > media breakpoint up
@mixin mq-up($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    // Use custom value passed
    @media (min-width: $breakpoint) {
      @content;
    }
  }
}

// This mixin will handle smaller than breakpoints
// @include media-breakpoint-down(bigScreenBreakpoint) {}
// mq-down > media breakpoint down
@mixin mq-down($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (max-width: $breakpoint-value) {
      @content;
    }
  } @else {
    // Use custom value passed
    @media (max-width: $breakpoint) {
      @content;
    }
  }
}

// This mixin will handle sizez between breakpoints you choose
// @include media-breakpoint-between(mobileBreakpoint, smallScreenWidth) {}
// mq-between > media breakpoint between
@mixin mq-between($lower, $upper) {
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    @if ($lower-breakpoint > $upper-breakpoint) {
      // Send error if first parameter size is bigger then the second
      @error "First parameter should represent lower value and it's size should be smaller. In your case #{$lower} > #{$upper}!";
    }

    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint)) {
      @content;
    }
  } @else {
    // If custom values
    @if ($lower > $upper) {
      // Send error if first parameter size is bigger then the second
      @error "First parameter should represent lower value and it's size should be smaller. In your case #{$lower}px > #{$upper}px!";
    }

    @media (min-width: $lower) and (max-width: ($upper)) {
      @content;
    }
  }
}

%skeleton-animation {
  background: linear-gradient(-90deg, #efefef 0%, #e6e9f0 50%, #efefef 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }

  &-dark {
    background: linear-gradient(-90deg, #042e53 0%, #033a6d 50%, #042e53 100%);
  }
}

.container {
  padding-bottom: 60px;
}

.topBar {
  color: $textPrimary;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  padding: 4px 12px;
  background: $lightGreenBright;
  text-align: center;
  display: block;
  margin-bottom: 22px;

  &:hover {
    color: $textPrimary;
    text-decoration: none;
  }
}

.content {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;

  @include mq-down(bigScreenBreakpoint) {
    padding: 0 16px;
  }

  .back {
    margin-bottom: 16px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    @include mq-down(mobileBreakpoint) {
      flex-direction: column;
    }

    .headerLeft {
      display: flex;
      align-items: center;

      @include mq-down(mobileBreakpoint) {
        margin-bottom: 16px;
      }

      .image {
        margin-right: 16px;
      }

      .titleWrapper {
        .title {
          font-size: 20px;
          font-family: Poppins;
          font-weight: 600;
          margin: 0 0 4px;
        }

        .address {
          font-size: 14px;
          line-height: 21px;
          font-style: normal;
          margin: 0;
        }
      }
    }

    .headerRight {
      display: flex;
      align-items: center;

      .tag {
        background: $lightGreenBright;
        margin-right: 16px;
      }
    }
  }

  .mainContent {
    display: flex;
    gap: 16px;

    @include mq-down(largeScreenBreakpoint) {
      flex-direction: column-reverse;
    }

    .mainContentLeft {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 50%;

      @include mq-down(largeScreenBreakpoint) {
        width: 100%;
      }
    }

    .detailsCard {
      padding: 32px;
      border: 2px solid $grayLight;
      border-radius: 4px;
      background: $white;

      @include mq-down(smallScreenWidth) {
        padding: 16px;
      }

      .detailsTitleWrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .detailsTitle {
          font-size: 18px;
          font-family: Poppins, sans-serif;
          font-weight: 600;
          margin: 0;
        }

        .editBtn {
          text-decoration: none;
        }

        .editIcon {
          height: 20px;
          width: 20px;
        }
      }

      .detailsItem {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
        margin-bottom: 10px;

        .detailsItemTitle {
          font-size: 14px;
          line-height: 21px;
          font-weight: 700;
        }

        .detailsItemContent {
          font-size: 14px;
          line-height: 21px;
        }
      }

      .imagesWrapper {
        display: flex;
        gap: 16px;

        .imageHolder {
          height: 130px;
          width: 130px;
          background-size: cover;
          overflow: hidden;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }

    .tenantCard {
      padding: 32px;
      border: 2px solid $grayLight;
      border-radius: 4px;
      background: $white;

      @include mq-down(smallScreenWidth) {
        padding: 16px;
      }

      .tenantCardTitle {
        font-size: 18px;
        font-weight: 600;
        font-family: Poppins, sans-serif;
        margin: 0 0 16px;
      }

      .tenantCardNameWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2px;

        .tenantCardName {
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
        }

        .messageIcon {
          &:disabled {
            svg {
              opacity: 0.5;
            }
          }
        }
      }

      .tenantCardText {
        display: block;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 2px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .mainContentRight {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 50%;

      @include mq-down(largeScreenBreakpoint) {
        width: 100%;
      }
    }

    .activityCard {
      padding: 32px;
      border: 2px solid $grayLight;
      border-radius: 4px;
      background: $white;

      @include mq-down(smallScreenWidth) {
        padding: 16px;
      }

      .activityItemWrapper {
        max-height: 400px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 7px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
      }

      .activityCardTitleWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        .activityCardTitle {
          display: flex;
          align-items: center;
          font-size: 18px;
          font-family: Poppins, sans-serif;
          font-weight: 600;
          margin: 0;
        }

        .activityCardInfo {
          margin-left: 4px;
        }
      }

      .activity {
        position: relative;
        display: flex;
        gap: 16px;

        &:nth-of-type(1)::before {
          content: '';
          position: absolute;
          top: -22px;
          left: 18px;
          height: 22px;
          width: 4px;
          background-color: $grayLight;
        }

        &::after {
          content: '';
          position: absolute;
          top: 40px;
          left: 18px;
          height: 100%;
          width: 4px;
          background-color: $grayLight;
        }

        &:last-of-type::after {
          height: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        .activityIconWrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          background: $grayLight;
          width: 40px;
          height: 40px;
          border-radius: 50%;

          .activityIcon {
            width: 24px;
            height: 24px;
            fill: $textPrimary;
          }
        }

        .activityContent {
          display: flex;
          flex-direction: column;
          margin-bottom: 24px;

          &.activityContentNoMargin {
            margin-bottom: 0;
          }

          .activityTitle {
            font-size: 14px;
            line-height: 21px;
            font-weight: bold;
            margin: 0 0 4px;
          }

          .activityDate {
            color: $textLight;
            font-size: 12px;
            line-height: 18px;
            font-weight: bold;
            display: block;
            margin-bottom: 4px;
          }

          .activityReceipt {
            display: flex;
            align-items: center;
            background: none;
            border: none;
            padding: 0;
            cursor: pointer;

            .activityReceiptIcon {
              margin-right: 5px;
            }

            .activityReceiptTitle {
              color: $textPrimary;
              font-size: 12px;
              line-height: 18px;
              font-weight: 600;
              text-decoration: underline;

              &:hover {
                color: $secondaryBlue;
              }
            }
          }

          .activityItem {
            margin-bottom: 4px;

            &:last-child {
              margin-bottom: 0;
            }

            .activityItemTitle {
              font-size: 12px;
              line-height: 18px;
              font-weight: bold;
              display: inline-block;
              margin-right: 4px;
            }

            .activityItemInfo {
              font-size: 12px;
              line-height: 18px;
            }
          }

          .activityAttachment {
            display: flex;
            align-items: center;
            margin-top: 4px;

            .activityAttachmentIcon {
              margin-right: 5px;
            }

            .activityAttachmentTitle {
              font-size: 12px;
              line-height: 18px;
              font-weight: 600;
              text-decoration: underline;
            }
          }
        }
      }
    }

    .promoCard {
      padding: 32px;
      border: 2px solid $grayLight;
      border-radius: 4px;
      background: $backgroundCard;

      @include mq-down(smallScreenWidth) {
        padding: 16px;
      }

      &.promoCardMobile {
        @include mq-up(smallScreenWidth) {
          display: none;
        }
      }

      &.promoCardDesktop {
        @include mq-down(smallScreenWidth) {
          display: none;
        }
      }

      .promoCardTitle {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-family: Poppins, sans-serif;
        font-weight: 600;
        margin: 0 0 16px;

        .promoCardIcon {
          width: 46px;
          fill: $textPrimary;
          margin-left: 4px;
        }
      }

      .benefitsWrapper {
        margin-bottom: 16px;

        .checkListItem {
          display: flex;

          .checkIcon {
            flex-shrink: 0;
            width: 20px;
            height: 20px;
          }

          .checkContent {
            margin-left: 10px;

            .checkTitle {
              font-size: 14px;
              line-height: 21px;
              font-weight: 600;
              margin: 0;
            }
          }

          &:not(:last-child) {
            margin-bottom: 12px;
          }
        }
      }

      .buttonWrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .button {
          width: 100%;
          max-width: 144px;
        }

        .button:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }
}
