// Primary Interface Colors
$primaryBlue: #033a6d; // Dark blue for primary branding
$secondaryBlue: #2370a3; // Secondary branding blue
$accentOrange: #f9a825; // Bright orange for CTA buttons or highlights
$grayLight: #e6e9f0; // General light gray used for card borders, lines, and disabled labels

// Background Colors
$backgroundLight: #f5f6f8; // Lightest blue for backgrounds
$backgroundDisabled: #f0f2f5; // Very light gray for disabled input fields
$backgroundCard: #e7f8fd; // Light blue for card headers
$darkBlueBackground: #052144; // Very dark blue, used for subscribe banners and impactful backgrounds
$lightGreyBackground: #faf9f8;

// Functional Colors
$error: #ff3b3b; // Bright red for errors
$errorDark: #f44336; // Dark red used for error texts
$errorLight: #fbf3f2; // Very light pink for error backgrounds
$warning: #bf2c2c; // Dark red for warnings
$warningLight: #ff9f2a; // Bright orange for less severe warnings
$warningBackground: #fff8e3; // Light yellow for warning backgrounds
$success: #43a047; // Green for success messages
$successLight: #e6f9f4; // Very light green for success backgrounds
$orangeLight: #ff9250; // Bright orange, used for less critical alerts and interactive elements
$warningYellow: #aa6000; // Dark yellow, used for warning messages and caution indicators
$grayExtraLight: #f0f5f8; // Very light gray, used for subtle backgrounds, such as under information blocks or secondary interface elements

// Text Colors
$info: #00c8ff; // Bright cyan for informational messages
$textPrimary: #042238; // Darkest blue for primary text
$textSecondary: #333f4d; // Dark blue for secondary text
$textTertiary: #445163; // Gray blue for tertiary text
$textLight: #516a80; // Light blue for less prominent text
$textPale: #6d757f; // Light gray for less important text
$textGrayLight: #acb9c8;

// Special Use Colors
$profileBlue: #5da7bc; // Soft blue for profile-related UI elements
$listBackground: #627186; // Gray blue for list backgrounds or count badges
$labelMarketingYellow: #f0dfb1; // Pale yellow for marketing labels
$progressGreen: #009e74; // Green for progress bars

// Alert Colors
$alertHigh: #ea4d45; // Red for high risk alerts
$alertMedium: #f8cd46; // Yellow for medium risk alerts
$alertLow: #5abc91; // Green for low risk alerts
$successGreen: #00805f; // Dark green, used for success states and positive indicators

// Brands and Social Media Colors
$facebookBlue: #1877f2; // Bright blue for Facebook-related buttons
$googleBlue: #4285f4; // Slightly lighter blue for Google-related buttons
$redfinRed: #8d2823; // Redfin red for branding
$reiHubPurple: #431c5d; // Dark purple for REI Hub branding

// Miscellaneous
$highlightYellow: #ffcd00; // Bright yellow used for highlighting important elements
$lightGreenBright: #04bf8d; // Bright green for alternative interactive elements
$iceBlue: #c9edff; // Light blue for cold or less emphatic elements
$iceBlueAlt: #dbe7ff; // Very light blue for alternative cold elements
$grayScale: #d8d8d8; // Light gray for borders or subtle backgrounds
$pink: #e91e63; // Bright pink, used for attention-grabbing elements or promotional highlights
$lightCardHeaderBackground: #f5f8fe; // Very light blue, used for card headers and sections
$modalLightBlueBackground: #f5f9ff; // Lightest blue, used for modal backgrounds to promote focus
$helpCardBackground: #a9f3df; // Very light mint green, used for refreshing or calm elements
$sureBlue: #022c52; // Slightly lighter than $darkBlueBackground, used for formal or serious contexts
$lightRedBackground: #feefef; // Very light red, used for background highlighting in error states
$darkGray: #687a88; // Dark gray, used for less emphasized text or subdued UI elements
$blueTag: #7fe3ff; // Light blue, used for tags and markers
$hoverGray: #d9e1e9; // Light gray, used for hover states or subtle backgrounds
$steelBlue: #4f7599; // Steel Blue, used for hover state for tabs on Premium Modal
$errorGray: #9ba7af; // Light Gray, used for error filled icon in ComparisonChart
$prominentOrangeGhost: #c85028;

// Standard Colors
$white: #ffffff; // White used for pure background
$black: #21282a; // Black used for text or deep shadows

$title-font: Poppins, sans-serif;

@mixin title-extra-bold {
  font-weight: 800;
  font-family: $title-font;
}

@mixin title-bold {
  font-weight: bold;
  font-family: $title-font;
}

@mixin title-semi-bold {
  font-weight: 600;
  font-family: $title-font;
}

@mixin title-medium {
  font-weight: 500;
  font-family: $title-font;
}

@mixin title-regular {
  font-weight: normal;
  font-family: $title-font;
}

$smallMobileBreakpoint: 320px;
$mobileBreakpoint: 479px;
$smallScreenWidth: 768px;
$largeScreenBreakpoint: 969px;
$tabsLayoutBreakpoint: 1200px;
$tabletBreakpoint: 1024px;
$bigScreenBreakpoint: 1400px;

$breakpoints: (
  smallMobileBreakpoint: $smallMobileBreakpoint,
  mobileBreakpoint: $mobileBreakpoint,
  largeScreenBreakpoint: $largeScreenBreakpoint,
  smallScreenWidth: $smallScreenWidth,
  tabsLayoutBreakpoint: $tabsLayoutBreakpoint,
  tabletBreakpoint: $tabletBreakpoint,
  bigScreenBreakpoint: $bigScreenBreakpoint,
);

// This mixin will handle larger than breakpoints
// @include media-breakpoint-up(smallMobileBreakpoint) {}
// mq-up > media breakpoint up
@mixin mq-up($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    // Use custom value passed
    @media (min-width: $breakpoint) {
      @content;
    }
  }
}

// This mixin will handle smaller than breakpoints
// @include media-breakpoint-down(bigScreenBreakpoint) {}
// mq-down > media breakpoint down
@mixin mq-down($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (max-width: $breakpoint-value) {
      @content;
    }
  } @else {
    // Use custom value passed
    @media (max-width: $breakpoint) {
      @content;
    }
  }
}

// This mixin will handle sizez between breakpoints you choose
// @include media-breakpoint-between(mobileBreakpoint, smallScreenWidth) {}
// mq-between > media breakpoint between
@mixin mq-between($lower, $upper) {
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    @if ($lower-breakpoint > $upper-breakpoint) {
      // Send error if first parameter size is bigger then the second
      @error "First parameter should represent lower value and it's size should be smaller. In your case #{$lower} > #{$upper}!";
    }

    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint)) {
      @content;
    }
  } @else {
    // If custom values
    @if ($lower > $upper) {
      // Send error if first parameter size is bigger then the second
      @error "First parameter should represent lower value and it's size should be smaller. In your case #{$lower}px > #{$upper}px!";
    }

    @media (min-width: $lower) and (max-width: ($upper)) {
      @content;
    }
  }
}

%skeleton-animation {
  background: linear-gradient(-90deg, #efefef 0%, #e6e9f0 50%, #efefef 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }

  &-dark {
    background: linear-gradient(-90deg, #042e53 0%, #033a6d 50%, #042e53 100%);
  }
}

.container {
  position: fixed;
  top: 0px;
  width: 200px;
  height: 100%;
  z-index: 999;
  overflow-y: auto;
  background-color: $textPrimary;
  transition: all 0.2s ease;

  &.withGlobalBanner {
    top: 44px;
  }

  &.isRenterNavigation {
    .fadeElement {
      display: none;
    }
  }

  @include mq-down(smallScreenWidth) {
    visibility: hidden;
  }

  .navigationWrapper {
    width: 100%;
    height: 100%;
    overflow: visible;
    padding: 16px 0;
    z-index: 1012;

    @media print {
      display: none;
    }

    .logoWrap {
      width: 138px;
      height: 56px;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin-bottom: 16px;

      svg,
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    .itemsContainer {
      height: calc(100% - 100px); // offseting the logo and promo tag height
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      padding-bottom: 50px;
      overflow-y: hidden;
      border-right: 4px solid $textPrimary; // This will create spacing between scrollbar and right edge of the screen

      &:hover {
        overflow-y: auto;
      }

      &::-webkit-scrollbar {
        width: 6px;
        display: block;
        margin-right: 10px;
        z-index: 2;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #808b96;
        border-radius: 4px;
      }

      &.renter {
        height: var(--global-banner-offset-renter-ui);
        padding-bottom: 15px;
      }

      .catLabel {
        color: $textGrayLight;
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        text-transform: uppercase;
        display: block;
        font-weight: 600;
        line-height: 21px;
        padding: 0 16px;
        margin: 20px 0 4px;

        &.hide {
          display: none;
        }
      }

      .navItem {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        height: 36px;
        padding: 0 15px;
        margin: 0 6px 4px;
        border-radius: 4px;
        position: relative;
        background-color: $textPrimary;
        transition: background-color 0.5s ease;
        white-space: nowrap;
        gap: 12px;

        &.hasSecondaryIcon {
          .secondaryIconWrap {
            transition: all 0.2s ease;
            position: absolute;
            right: 16px;
          }
        }

        &.active {
          background-color: $primaryBlue;
          text-decoration: none;

          .label {
            color: $grayLight;
          }

          > .iconWrap {
            svg {
              fill: $white;

              g {
                fill: $white;
              }

              path {
                fill: $white;
              }
            }
          }
        }

        &:hover {
          background-color: $primaryBlue;
          text-decoration: none;
          overflow-y: hidden !important;

          .menuSubMenu {
            width: 200px;
            opacity: 1;
            overflow: visible;

            .navItem {
              margin: 0;
            }

            &.wide {
              width: 224px;
              overflow: hidden;
            }
          }

          .secondaryIconWrap {
            transform: rotate(180deg);
          }

          .iconWrap {
            transform: scale(1.1);
          }

          .label {
            color: $grayLight;
          }
        }

        .wide {
          .navItem,
          .hasOptions {
            width: 224px;
          }

          .iconWrap {
            transform: none;
          }
        }

        .label {
          color: $textGrayLight;
          font-family: 'Open Sans', sans-serif;
          font-size: 13px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 20px;
        }

        .newWindowIcon {
          margin-left: -7px;
        }

        .iconWrap {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 24px;
          width: 24px;
          transition: transform 0.25s;

          .referralIcon {
            height: 20px;
            width: 20px;
          }
        }

        .badge {
          height: 16px;
          line-height: 16px;
          min-width: 16px;
          background-color: $secondaryBlue;
        }

        .menuSubMenu {
          width: 0;
          border-radius: 8px;
          position: fixed;
          bottom: 3px;
          left: 184px;
          padding: 8px 0;
          opacity: 0;
          margin-left: 18px;
          background-color: $textPrimary;
          overflow: hidden;
          transition: all 0.2s ease;
          box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.23);
          -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.23);
          -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.23);

          &.active {
            width: 200px;
            left: 170px;
            opacity: 1;
            overflow: visible;
          }

          .label {
            color: $textGrayLight;
          }
        }
      }

      div {
        &:last-child {
          .navItem {
            &:last-child {
              z-index: 2;
            }
          }
        }
      }
    }

    .hasOptions {
      display: block;
      flex-direction: column;
      height: auto;
      align-items: flex-start;
      cursor: initial;
      border-bottom: 1px solid $textGrayLight;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .options {
        max-height: calc(100vh - 150px);
        overflow-y: auto;
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
      }

      & > .label {
        display: flex;
        align-items: center;
        padding: 0 16px;
        height: 36px;
      }

      .navItem {
        padding: 6px 16px;
        &.active {
          height: auto;
          min-height: 36px;
        }
        &:not(.active) {
          .label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .check {
          flex-shrink: 0;
          color: $white;
          margin-left: auto;
        }
      }
    }

    .fadeElement {
      position: fixed;
      width: 184px;
      height: 37px;
      left: 0;
      bottom: 50px;
      z-index: 1;
      background: linear-gradient(180deg, rgba(4, 34, 56, 0) 0%, #042238 100%);
      pointer-events: none;
    }

    .navBottom {
      .navItem {
        // account tag specific styles
        &.account {
          border-top: 1px solid $textLight;
          position: fixed;
          width: 200px;
          height: 50px;
          left: 0;
          bottom: 0;
          z-index: 999;

          > .label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 8px;
          }

          > .secondaryIconWrap {
            position: initial;
            margin-left: auto;
          }
        }

        &:last-child {
          margin: 0;
        }

        .profilePicture {
          width: 32px;
          height: 32px;
          min-width: 32px;
          min-height: 32px;
        }
      }
    }
  }
}
